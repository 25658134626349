import { cloneDeep, defaultsDeep, last, range } from 'lodash-es';
import {
    BehaviorSubject,
    Observable,
    combineLatest,
    firstValueFrom,
    of
} from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    filter,
    first,
    map,
    shareReplay,
    switchMap,
    take,
    tap
} from 'rxjs/operators';
import Swiper from 'swiper';

import { animate, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    Inject,
    NgZone,
    ViewContainerRef
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { translate } from '@jsverse/transloco';
import { GenericOption } from '@mhp-immersive-exp/contracts/src/configuration/configuration-payload.interface';
import { Identifiable } from '@mhp-immersive-exp/contracts/src/generic/identifiable.interface';
import { DerivativeInteriorEnvironmentDefaultSelections } from '@mhp/aml-shared/derivate-mapping/derivative-mapping.interfaces';
import { slugifyString } from '@mhp/aml-shared/helper/utilities.frontend.helper';
import { EngineSessionData } from '@mhp/aml-ui-shared-services';
import {
    IllegalStateError,
    MemoizeObservable,
    NgZoneAware,
    RunInZone,
    distinctUntilChangedEquality,
    lazyShareReplay
} from '@mhp/common';
import {
    ImageSrcset,
    TRACK_BY_ID,
    UiMatDialogService
} from '@mhp/ui-components';
import {
    I18nService,
    StaticAssetService,
    isOptionCode
} from '@mhp/ui-shared-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { environment } from '../../../../../environments/environment';
import { AmlImagesService } from '../../../../common/images/aml-images.service';
import { AmlProductDataService } from '../../../../product-data/aml-product-data-service';
import {
    ExtendedUiOptionCode,
    ExtendedUiOptionCollection,
    ExtendedUiOptionGroup
} from '../../../configuration-model/configuration-interfaces';
import { isExtendedUiOptionCollection } from '../../../services/configuration-helper';
import { ConfigurationNodeLookupService } from '../../../services/configuration-node-lookup.service';
import { ProductConfigurationSessionService } from '../../../services/product-configuration-session.service';
import { ConfigurationSessionInfoService } from '../../../session-info/configuration-session-info.service';
import { StaticRendererService } from '../../../static-renderer/static-renderer.service';
import {
    EnvironmentSelectionInfoComponent,
    EnvironmentSelectionInfoData
} from '../environment-selection-info/environment-selection-info.component';
import { EnvironmentSelectionTurntableComponent } from '../environment-selection-turntable/environment-selection-turntable.component';
import {
    InteriorEnvironmentGroupModel,
    InteriorEnvironmentModel
} from '../environment-selection.interface';
import { EnvironmentSelectionService } from '../environment-selection.service';

export interface EnvironmentSelectionDialogData {
    readonly?: boolean;
}

type SelectedEnvironments = DerivativeInteriorEnvironmentDefaultSelections;

@UntilDestroy()
@Component({
    selector: 'mhp-environment-selection-dialog',
    templateUrl: './environment-selection-dialog.component.html',
    styleUrls: ['./environment-selection-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('fade', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.25s ease-in-out', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.25s ease-in-out', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class EnvironmentSelectionDialogComponent implements NgZoneAware {
    readonly trackById = TRACK_BY_ID;

    readonly productIdSubject = new BehaviorSubject<string | undefined>(
        undefined
    );

    readonly activeIndexSubject = new BehaviorSubject<number>(0);

    readonly activeIndex$ = this.activeIndexSubject
        .asObservable()
        .pipe(distinctUntilChanged());

    private activeEnvironment$ =
        new BehaviorSubject<null | InteriorEnvironmentModel>(null);

    private selectedEnvironments$ =
        new BehaviorSubject<null | SelectedEnvironments>(null);

    swiper?: Swiper;

    readonly identifiableComparator = (
        item1: Identifiable,
        item2: Identifiable
    ) => item1?.id === item2?.id;

    constructor(
        private readonly productConfigurationSessionService: ProductConfigurationSessionService,
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService,
        private readonly nodeLookupService: ConfigurationNodeLookupService,
        private readonly i18nService: I18nService,
        private readonly staticAssetService: StaticAssetService,
        private readonly activatedRoute: ActivatedRoute,
        public readonly ngZone: NgZone,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly dialogRef: MatDialogRef<EnvironmentSelectionDialogComponent>,
        private readonly dialogService: UiMatDialogService,
        private readonly breakpointObserver: BreakpointObserver,
        private readonly environmentSelectionService: EnvironmentSelectionService,
        private readonly staticRendererService: StaticRendererService,
        private readonly productDataService: AmlProductDataService,
        public readonly amlImagesService: AmlImagesService,
        @Inject(MAT_DIALOG_DATA)
        public readonly data?: EnvironmentSelectionDialogData
    ) {
        this.initSelectedEnvironments();
        this.initActiveEnvironment();

        this.configurationSessionInfoService
            .getActiveProductId$()
            .pipe(untilDestroyed(this))
            .subscribe(this.productIdSubject);
    }

    @RunInZone()
    onSwiperInitialized(swiper: Swiper) {
        this.swiper = swiper;
        // Intialize the dialog with a previously selected environment
        this.initDefaultSelectedEnvironment();
    }

    @RunInZone()
    onSwiperSlideChanged(
        environmentGroups: InteriorEnvironmentGroupModel[],
        selectedEnvironments: SelectedEnvironments
    ) {
        if (!this.swiper) {
            return;
        }
        const activeIndex = this.swiper.realIndex;

        this.activeIndexSubject.next(activeIndex);
        this.activeEnvironment$.next(
            environmentGroups?.[activeIndex]?.environments?.find(
                (e) =>
                    e.optionCode.code ===
                    selectedEnvironments[environmentGroups?.[activeIndex]?.id]
            ) || environmentGroups?.[activeIndex]?.environments[0]
        );
    }

    goToSlide(index: number, speed?: number) {
        this.swiper?.slideToLoop(index, speed);
    }

    intentPrevSlide() {
        this.swiper?.slidePrev();
    }

    intentNextSlide() {
        this.swiper?.slideNext();
    }

    getSelectedInteriorEnvironmentModel(
        environmentGroupModel: InteriorEnvironmentGroupModel,
        selectedEnvironments: SelectedEnvironments
    ): InteriorEnvironmentModel | undefined {
        return environmentGroupModel.environments.find(
            (e) =>
                e.optionCode.code ===
                selectedEnvironments[environmentGroupModel.id]
        );
    }

    @MemoizeObservable()
    getActiveEnvironmentGroupImageSrcset$(
        environmentGroupModel: InteriorEnvironmentGroupModel
    ): Observable<ImageSrcset | string | undefined> {
        return this.getSelectedEnvironments$().pipe(
            map((selectedEnvironments) => {
                if (!selectedEnvironments) {
                    return undefined;
                }

                return this.getSelectedInteriorEnvironmentModel(
                    environmentGroupModel,
                    selectedEnvironments
                );
            }),
            distinctUntilChangedEquality(),
            switchMap((selectedInteriorEnvironmentModel) => {
                if (!selectedInteriorEnvironmentModel) {
                    return of(undefined);
                }
                return this.getDefaultEnvironmentImageSrcset$(
                    selectedInteriorEnvironmentModel
                );
            }),
            map(
                (
                    imageSrcset // emit an invalid url for the fallback-image to kick in
                ) => imageSrcset ?? 'invalid'
            ),
            lazyShareReplay()
        );
    }

    @MemoizeObservable()
    getDefaultEnvironmentImageSrcset$(
        environmentModel: InteriorEnvironmentModel
    ): Observable<ImageSrcset | undefined> {
        return this.getInteriorEnvironmentImageSrcsetFactory$(
            environmentModel
        ).pipe(
            map((factoryCallback) => {
                if (!factoryCallback) {
                    return undefined;
                }
                return factoryCallback(
                    (engineSessionData) => engineSessionData
                );
            }),
            untilDestroyed(this),
            /* Cache the result until destruction.
             * Explicitly don't use lazyShareReplay, as we want to cache the result even in case no subscriber is left. When moving through the
             * slides and activate different selections, we want to re-use a srcset that has already been calculated
             */
            shareReplay()
        );
    }

    @MemoizeObservable()
    getActiveEnvironment$(): Observable<InteriorEnvironmentModel | null> {
        return this.activeEnvironment$
            .asObservable()
            .pipe(untilDestroyed(this));
    }

    @MemoizeObservable()
    getSelectedEnvironments$(): Observable<SelectedEnvironments | null> {
        return this.selectedEnvironments$
            .asObservable()
            .pipe(untilDestroyed(this));
    }

    @MemoizeObservable()
    getAvailableInteriorEnvironmentGroupModels$(): Observable<
        InteriorEnvironmentGroupModel[] | undefined
    > {
        return combineLatest([
            this.getRootLevelGroups$(),
            this.configurationSessionInfoService.getActiveModelId$(),
            this.configurationSessionInfoService.getActiveProductId$(),
            this.productDataService.getProductMeta$(),
            this.i18nService.getActiveLang$()
        ]).pipe(
            map(([rootLevelGroups, modelId, productId, productMeta]) => {
                if (
                    !rootLevelGroups ||
                    !modelId ||
                    !productId ||
                    !productMeta
                ) {
                    return undefined;
                }

                const environmentsCollection = this.nodeLookupService.findNode(
                    (node): node is ExtendedUiOptionCollection =>
                        node.name ===
                            environment.appConfig.configuration
                                .identifierEnvironmentSelection &&
                        isExtendedUiOptionCollection(node),
                    rootLevelGroups
                )?.node;
                if (!environmentsCollection) {
                    return undefined;
                }

                const interiorEnvironmentDefinition =
                    productMeta.interiorEnvironment.definition;

                const environmentGroups: InteriorEnvironmentGroupModel[] =
                    environmentsCollection.content.reduce(
                        (groups, optionCode) => {
                            if (!isOptionCode(optionCode) || !optionCode.meta) {
                                return groups;
                            }

                            const matchingInteriorEnvironment =
                                interiorEnvironmentDefinition.find(
                                    (definition) =>
                                        definition.relatedOptions.includes(
                                            optionCode.code
                                        )
                                );
                            if (!matchingInteriorEnvironment) {
                                return groups;
                            }

                            const environmentId =
                                matchingInteriorEnvironment.name;

                            const translationKeyPrefix =
                                slugifyString(environmentId);

                            let existingGroup = groups.find(
                                (group) => group.id === environmentId
                            );
                            if (!existingGroup) {
                                const labelModelFallback = translate(
                                    `INTERIOR_ENV_SELECTION.${modelId}.${translationKeyPrefix}_ENV`
                                );
                                const descriptionShortModelFallback = translate(
                                    `INTERIOR_ENV_SELECTION.${modelId}.${translationKeyPrefix}_DESC_SHORT`
                                );
                                const descriptionLongModelFallback = translate(
                                    `INTERIOR_ENV_SELECTION.${modelId}.${translationKeyPrefix}_DESC_LONG`
                                );
                                existingGroup = <InteriorEnvironmentGroupModel>{
                                    id: environmentId,
                                    label: this.i18nService.translateWithFallback(
                                        `INTERIOR_ENV_SELECTION.${modelId}.${productId}.${translationKeyPrefix}_ENV`,
                                        labelModelFallback
                                    ),
                                    descriptionShort:
                                        this.i18nService.translateWithFallback(
                                            `INTERIOR_ENV_SELECTION.${modelId}.${productId}.${translationKeyPrefix}_DESC_SHORT`,
                                            descriptionShortModelFallback
                                        ),
                                    descriptionLong:
                                        this.i18nService.translateWithFallback(
                                            `INTERIOR_ENV_SELECTION.${modelId}.${productId}.${translationKeyPrefix}_DESC_LONG`,
                                            descriptionLongModelFallback
                                        ),
                                    environments: [],
                                    isDefault:
                                        !!matchingInteriorEnvironment.default
                                };
                                groups.push(existingGroup);
                            }

                            // Remove group name in front of env name
                            const label =
                                last(
                                    optionCode.nameTranslated
                                        .replace('–', '-')
                                        .split('-')
                                )?.trim() || optionCode.nameTranslated;

                            existingGroup.environments.push({
                                id: optionCode.id,
                                name: optionCode.name ?? '',
                                label,
                                optionCode
                            });

                            return groups;
                        },
                        <InteriorEnvironmentGroupModel[]>[]
                    );

                return environmentGroups;
            }),
            lazyShareReplay()
        );
    }

    @MemoizeObservable()
    isMobilePortrait$() {
        return this.breakpointObserver
            .observe([Breakpoints.HandsetPortrait])
            .pipe(map((state) => state.matches));
    }

    @RunInZone()
    configureEnvironment(environmentModel: InteriorEnvironmentModel) {
        this.environmentSelectionService
            .applyEnvironment$(environmentModel)
            .subscribe(() => this.dialogRef.close());
    }

    intentShowEnvironmentInfoLayer(
        environmentGroupModel: InteriorEnvironmentGroupModel,
        environmentModel: InteriorEnvironmentModel
    ) {
        this.getDefaultEnvironmentImageSrcset$(environmentModel)
            .pipe(
                take(1),
                switchMap((environmentImageSrcset) =>
                    this.dialogService.openFullscreen$(
                        EnvironmentSelectionInfoComponent,
                        {
                            componentFactoryResolver:
                                this.componentFactoryResolver,
                            viewContainerRef: this.viewContainerRef,
                            data: <EnvironmentSelectionInfoData>{
                                environmentGroupModel,
                                environmentModel,
                                parentDialogRef: this.dialogRef,
                                productId: this.productIdSubject.value,
                                interiorImageSrcset: environmentImageSrcset,
                                readonly: !!this.data?.readonly
                            }
                        }
                    )
                ),
                untilDestroyed(this)
            )
            .subscribe(() => this.changeDetectorRef.markForCheck());
    }

    intentShowEnvironmentTurntable(environmentModel: InteriorEnvironmentModel) {
        this.getInteriorEnvironmentImageSrcsetFactory$(environmentModel)
            .pipe(
                take(1),
                switchMap((srcsetFactory) => {
                    if (!srcsetFactory) {
                        return of(undefined);
                    }
                    const interiorImageSrcsets: ImageSrcset[] = range(
                        -45,
                        45,
                        environment.appConfig.configuration.interiorEnvironment
                            .degreeStep
                    ).map((currentDegree) =>
                        srcsetFactory((engineSessionData) =>
                            defaultsDeep(
                                <Partial<EngineSessionData>>(<unknown>{
                                    camera: <Partial<GenericOption>>{
                                        options: {
                                            degree: currentDegree
                                        }
                                    }
                                }),
                                engineSessionData
                            )
                        )
                    );

                    return this.dialogService.openFullscreen$(
                        EnvironmentSelectionTurntableComponent,
                        {
                            componentFactoryResolver:
                                this.componentFactoryResolver,
                            viewContainerRef: this.viewContainerRef,
                            data: {
                                interiorImageSrcsets
                            }
                        }
                    );
                }),
                untilDestroyed(this)
            )
            .subscribe(() => this.changeDetectorRef.markForCheck());
    }

    @RunInZone()
    setActiveEnvironment(
        environmentGroupModel: InteriorEnvironmentGroupModel,
        environmentModel: InteriorEnvironmentModel
    ) {
        const selectedEnvironments = this.selectedEnvironments$.value;
        if (selectedEnvironments) {
            selectedEnvironments[environmentGroupModel.id] =
                environmentModel.optionCode.code;
            this.selectedEnvironments$.next(selectedEnvironments);
        }

        this.activeEnvironment$.next(environmentModel);
    }

    replaceAll(string, occurrance, replacement): string {
        return string.split(occurrance).join(replacement);
    }

    private initActiveEnvironment() {
        combineLatest([
            this.getAvailableInteriorEnvironmentGroupModels$().pipe(
                filter((groups) => !!groups)
            ),
            this.selectedEnvironments$.pipe(
                filter((selectedEnvironments) => !!selectedEnvironments)
            )
        ])
            .pipe(
                first(),
                tap(([groups, selectedEnvironments]) => {
                    if (!groups) {
                        throw new IllegalStateError(
                            'Unable to select environment groups'
                        );
                    }
                    const environments = groups?.[0]?.environments;
                    this.activeEnvironment$.next(
                        environments?.find(
                            (e) =>
                                e.optionCode.code ===
                                selectedEnvironments?.[groups[0].id]
                        ) || environments?.[0]
                    );
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private initSelectedEnvironments() {
        this.configurationSessionInfoService
            .getActiveProductId$()
            .pipe(
                first(),
                switchMap(async (activeProductId) => {
                    if (!activeProductId) {
                        throw new IllegalStateError('Unable to get product id');
                    }
                    return this.getDefaultSelectedEnvironments(activeProductId);
                })
            )
            .subscribe((selectedEnvironments) => {
                this.selectedEnvironments$.next(
                    cloneDeep(selectedEnvironments)
                );
            });
    }

    private async getDefaultSelectedEnvironments(
        productId: string
    ): Promise<DerivativeInteriorEnvironmentDefaultSelections> {
        return firstValueFrom(
            this.productDataService.getProductMeta$(productId).pipe(
                map((meta) => {
                    return (meta?.interiorEnvironment?.definition ?? []).reduce(
                        (prev, current) => {
                            prev[current.name] = current.defaultRelatedOption;
                            return prev;
                        },
                        {} as DerivativeInteriorEnvironmentDefaultSelections
                    );
                })
            )
        );
    }

    private getRootLevelGroups$(): Observable<
        ExtendedUiOptionGroup[] | undefined
    > {
        return this.productConfigurationSessionService.getOptionGroups$();
    }

    private getInteriorEnvironmentImageSrcsetFactory$(
        environmentModel: InteriorEnvironmentModel
    ): Observable<
        | ((
              callback: (
                  engineSessionData: EngineSessionData
              ) => EngineSessionData
          ) => ImageSrcset)
        | undefined
    > {
        return combineLatest([
            this.environmentSelectionService.getHaloSpecConfigurationWithEnvironmentModelApplied$(
                environmentModel
            ),
            this.staticRendererService.getActiveSessionRenderingSrcsetFactory$()
        ]).pipe(
            map(([currentAdjustedConfiguration, imageSrcsetFactory]) => {
                if (!currentAdjustedConfiguration || !imageSrcsetFactory) {
                    return undefined;
                }

                return (callback) =>
                    imageSrcsetFactory((engineSessionData) => {
                        const baseAdjustments: EngineSessionData = {
                            ...engineSessionData,
                            config: {
                                ...engineSessionData.config,
                                options: {
                                    config: currentAdjustedConfiguration
                                }
                            },
                            environment: {
                                id: 'Studio',
                                options: {
                                    night: false
                                }
                            },
                            camera: {
                                id: environment.appConfig.configuration
                                    .interiorEnvironment.camera,
                                options: {
                                    degree: 0
                                }
                            },
                            animations: []
                        };
                        return callback(baseAdjustments);
                    });
            }),
            catchError(() => of(undefined))
        );
    }

    private initDefaultSelectedEnvironment() {
        this.productConfigurationSessionService
            .getOptionGroups$()
            .pipe(
                take(1),
                map((optionGroups) => {
                    if (!optionGroups) {
                        throw new IllegalStateError('Expected option groups');
                    }
                    return this.nodeLookupService.findNode(
                        (node): node is ExtendedUiOptionCollection =>
                            node.name ===
                                environment.appConfig.configuration
                                    .identifierEnvironmentSelection &&
                            isExtendedUiOptionCollection(node),
                        optionGroups
                    )?.node;
                }),
                switchMap((contentAware) => {
                    if (!contentAware) {
                        throw new IllegalStateError('Expected content aware');
                    }

                    const environmentOptionCode = contentAware.content.find(
                        (i): i is ExtendedUiOptionCode =>
                            isOptionCode(i) && i.selected
                    )?.code;
                    if (!environmentOptionCode) {
                        throw new IllegalStateError(
                            'Expected selected environment option code'
                        );
                    }

                    return this.getAvailableInteriorEnvironmentGroupModels$().pipe(
                        take(1),
                        tap((environmentGroupModels) => {
                            if (!environmentGroupModels) {
                                return;
                            }
                            const index = environmentGroupModels.findIndex(
                                (egm) =>
                                    egm.environments.find(
                                        (e) =>
                                            e.optionCode.code ===
                                            environmentOptionCode
                                    )
                            );
                            const environmentGroupModel =
                                environmentGroupModels[index];
                            const environmentModel =
                                environmentGroupModel.environments.find(
                                    (e) =>
                                        e.optionCode.code ===
                                        environmentOptionCode
                                );
                            if (environmentGroupModel && environmentModel) {
                                this.setActiveEnvironment(
                                    environmentGroupModel,
                                    environmentModel
                                );
                                this.goToSlide(index, 0);
                            }
                        })
                    );
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }
}
