import { combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';

import { ConfigurationSessionInfoService } from '../../configuration/session-info/configuration-session-info.service';
import { AmlProductDataService } from '../../product-data/aml-product-data-service';

@Injectable({
    providedIn: 'root'
})
export class AmlImagesService {
    constructor(
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService,
        private readonly productDataService: AmlProductDataService
    ) {}

    @MemoizeObservable()
    getModelSpecificLogoImageUrl$() {
        return combineLatest([
            this.configurationSessionInfoService.getActiveModelId$(),
            this.productDataService.getActiveProductInfo$()
        ]).pipe(
            map(([modelId, productInfo]) => {
                if (productInfo?.meta?.useDerivativeSpecificLogo) {
                    return `assets/images/model-select/${productInfo.id.toLowerCase()}.svg`;
                }
                return modelId
                    ? `assets/images/model-select/${modelId.toLowerCase()}.svg`
                    : undefined;
            }),
            distinctUntilChanged(),
            lazyShareReplay()
        );
    }

    @MemoizeObservable()
    getModelSpecificFallbackImageUrl$() {
        return combineLatest([
            this.configurationSessionInfoService.getActiveModelId$(),
            this.productDataService.getActiveProductInfo$()
        ]).pipe(
            map(([modelId, productInfo]) => {
                if (productInfo?.meta?.useDerivativeSpecificLogo) {
                    return `assets/images/placeholder_${productInfo.id}.svg`;
                }
                return modelId
                    ? `assets/images/placeholder_${modelId}.svg`
                    : undefined;
            }),
            distinctUntilChanged(),
            lazyShareReplay()
        );
    }
}
