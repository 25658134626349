import { keys, last } from 'lodash-es';
import { ClipboardService } from 'ngx-clipboard';
import {
    EMPTY,
    Observable,
    Subject,
    combineLatest,
    defer,
    of,
    timer
} from 'rxjs';
import {
    catchError,
    filter,
    finalize,
    first,
    map,
    startWith,
    switchMap,
    switchMapTo,
    take,
    takeUntil,
    tap,
    withLatestFrom
} from 'rxjs/operators';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ComponentFactoryResolver,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    Output,
    QueryList,
    Renderer2,
    ViewChildren,
    ViewContainerRef
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { translate } from '@jsverse/transloco';
import { ConfigurationSummaryGroup } from '@mhp-immersive-exp/contracts/src/ui/summary/configuration-summary-model.interface';
import { MSRP_COUNTRIES_SET } from '@mhp/aml-shared/country-mapping/msrp-countries';
import {
    ConfigurationCompositeItemModel,
    ConfigurationCompositeItemOptionModel
} from '@mhp/aml-ui-shared-components/configuration/configuration-elements';
import { sfEvent } from '@mhp/aml-ui-shared-services/salesforce-tracking';
import {
    CustomError,
    IllegalStateError,
    MemoizeObservable,
    connectedPublishReplay
} from '@mhp/common';
import {
    ImageSrcset,
    TRACK_BY_ID,
    UiBaseComponent,
    UiMatDialogService,
    UiNotificationService
} from '@mhp/ui-components';
import {
    ErrorHandlerService,
    ExtendedPlatform,
    I18nService,
    L10nService,
    UrlShortenerService,
    downloadFile,
    gtmGA4Track,
    isOptionCode,
    isOptionGroup
} from '@mhp/ui-shared-services';

import { environment } from '../../../../environments/environment';
import { AmlBreakpoints } from '../../../common/breakpoints/AmlBreakpoints';
import { BACKDROP_CLASS_BLURRY } from '../../../common/dialog/dialog.constants';
import { DEALER_HOOKS_TOKEN, DealerHooks } from '../../../dealer';
import { LabelHelperService } from '../../../i18n/label-helper.service';
import { AmlProductDataService } from '../../../product-data/aml-product-data-service';
import { SALESFORCE_EVENT_CONFIGURATOR_GET_LINK } from '../../../salesforce-events/salesforce-events';
import {
    RegionAndLanguageSelectionInterceptor,
    RegionAndLanguageSelectionOptions
} from '../../../settings/region-selector/region.service';
import { AmlCodeService } from '../../aml-code/aml-code.service';
import { buildRegionChangeInterceptor } from '../../common/region-change-interceptor';
import { ConfigurationCompositeItemService } from '../../configuration-area/configuration-elements/configuration-composite-item/configuration-composite-item.service';
import {
    ExtendedUiOptionCode,
    ExtendedUiOptionCollection,
    ExtendedUiOptionGroup
} from '../../configuration-model/configuration-interfaces';
import { ConfigurationSubmitService } from '../../configuration-submit/configuration-submit.service';
import { OfferRow } from '../../financial-services/financial-services.interfaces';
import { InfoLayerService } from '../../info-layer/info-layer.service';
import { RequestPdfViaEmailComponent } from '../../salesforce/request-pdf-via-email/request-pdf-via-email.component';
import { SalesforceContextService } from '../../salesforce/salesforce-context.service';
import {
    isExtendedUiOptionCollection,
    isExtendedUiOptionGroup
} from '../../services/configuration-helper';
import { ConfigurationNodeLookupService } from '../../services/configuration-node-lookup.service';
import { ProductConfigurationSessionService } from '../../services/product-configuration-session.service';
import { ConfigurationSessionInfoService } from '../../session-info/configuration-session-info.service';
import { ConfigurationSocialSharingService } from '../../social-sharing/configuration-social-sharing.service';
import { StaticRendererService } from '../../static-renderer/static-renderer.service';
import { TurntableDialogComponent } from '../../turntable-dialog/turntable-dialog.component';
import {
    ConfigurationSummaryService,
    ExtendedConfigurationPricingSummaryModel,
    ExtendedConfigurationPricingSummarySubEntry
} from '../configuration-summary.service';
import { RequestPdfComponent } from '../request-pdf/request-pdf.component';
import {
    ImgComparisonElement,
    SummaryImageProviderService
} from './summary-image-provider.service';

export interface SummaryImageProvider {
    getSummaryImages$(): Observable<ImageSrcset[] | undefined>;
}

@Component({
    selector: 'mhp-configuration-summary',
    templateUrl: './configuration-summary.component.html',
    styleUrls: ['./configuration-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ConfigurationSubmitService]
})
export class ConfigurationSummaryComponent
    extends UiBaseComponent
    implements AfterViewInit
{
    @ViewChildren('summarySlider', {
        emitDistinctChangesOnly: true
    })
    summarySliderQueryList: QueryList<ElementRef<HTMLDivElement>>;

    @Input()
    showRightLane = true;

    @Input()
    regionAndLanguageSelectionOptions?: RegionAndLanguageSelectionOptions;

    @Input()
    renderTurntableCta = true;

    @Output()
    readonly optionSelected = new EventEmitter<string>();

    summarySliderPaginationVisibleStatus$?: Observable<boolean>;

    summarySliderAutoplayStatus$?: Observable<boolean>;

    financialServicesShowMonthlyRate = false;

    readonly IS_DEALER_BUILD = environment.appConfig.dealer.dealerBuild;

    readonly regionAndLanguageSelectorInterceptor: RegionAndLanguageSelectionInterceptor;

    readonly updatingOptionSelection = toSignal(
        this.productConfigurationSessionService.isSelectionChangeActive$(),
        {
            initialValue: false
        }
    );

    private readonly userInteractionSubject = new Subject<'CLIPBOARD'>();

    readonly trackById = TRACK_BY_ID;

    readonly trackByLabel = (index: number, pricingEntry: { label: string }) =>
        pricingEntry.label;

    constructor(
        public readonly labelHelperService: LabelHelperService,
        private readonly summaryService: ConfigurationSummaryService,
        private readonly notificationService: UiNotificationService,
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService,
        private readonly staticRendererService: StaticRendererService,
        private readonly dialogService: UiMatDialogService,
        private readonly infoLayerService: InfoLayerService,
        private readonly configurationCompositeItemService: ConfigurationCompositeItemService,
        private readonly nodeLookupService: ConfigurationNodeLookupService,
        private readonly productConfigurationSessionService: ProductConfigurationSessionService,
        private readonly productDataService: AmlProductDataService,
        private readonly breakpointObserver: BreakpointObserver,
        private readonly i18nService: I18nService,
        private readonly l10nService: L10nService,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        @Inject(DEALER_HOOKS_TOKEN)
        private readonly dealerHooksService: DealerHooks,
        private readonly urlShortenerService: UrlShortenerService,
        private readonly errorHandlerService: ErrorHandlerService,
        private readonly clipboardService: ClipboardService,
        private readonly renderer: Renderer2,
        private readonly configurationSocialSharingService: ConfigurationSocialSharingService,
        private readonly platform: ExtendedPlatform,
        private readonly amlCodeService: AmlCodeService,
        private readonly summaryImageProviderService: SummaryImageProviderService,
        private readonly configurationSubmitService: ConfigurationSubmitService,
        private readonly salesforceContextService: SalesforceContextService
    ) {
        super();

        this.regionAndLanguageSelectorInterceptor =
            buildRegionChangeInterceptor(
                this.productConfigurationSessionService
            );

        this.completeOnDestroy(
            this.optionSelected,
            this.userInteractionSubject
        );
    }

    ngAfterViewInit() {
        this.initStickyHeaderBehavior();
        this.initPaginationVisibleStatus();
        this.initAutoplayStatus();
    }

    intentSubmitToSalesforceOrVoe() {
        this.configurationSubmitService.intentSubmitConfiguration();
    }

    intentRequestPdfAsEmail() {
        this.dialogService
            .openFullscreen$(RequestPdfViaEmailComponent, {
                componentFactoryResolver: this.componentFactoryResolver,
                viewContainerRef: this.viewContainerRef
            })
            .pipe(this.takeUntilDestroy())
            .subscribe();
    }

    intentDownloadPdf() {
        gtmGA4Track('download_personalised_brochure_click');
        this.dialogService
            .open$(RequestPdfComponent, {
                width: '550px',
                backdropClass: BACKDROP_CLASS_BLURRY,
                componentFactoryResolver: this.componentFactoryResolver,
                viewContainerRef: this.viewContainerRef
            })
            .pipe(
                tap((dialogRef) => {
                    const componentRef = dialogRef.componentInstance;
                    componentRef.dealerMode =
                        environment.appConfig.dealer.dealerBuild;
                }),
                this.takeUntilDestroy()
            )
            .subscribe();
    }

    intentShowConfigurationCode() {
        gtmGA4Track('configuration_code_click');
        combineLatest([
            this.getShortCode$(),
            this.getShortenedConfigurationUrl$()
        ])
            .pipe(
                take(1),
                switchMap(([code, shortUrl]) => {
                    gtmGA4Track('create_configuration_link_success');

                    return this.amlCodeService.openShowAmlCodeDialog$(
                        code,
                        shortUrl,
                        this.viewContainerRef,
                        this.componentFactoryResolver
                    );
                }),
                this.takeUntilDestroy()
            )
            .subscribe({
                error: () => gtmGA4Track('create_configuration_link_failed')
            });
    }

    intentCopyToClipboard() {
        gtmGA4Track('your_configuration_link_click');

        this.userInteractionSubject.next('CLIPBOARD');

        let urlSource$: Observable<string>;

        if (this.platform.IOS || this.platform.IPAD_OS) {
            /* for iOS we cannot trigger the copy-to-cb call in response to the shorten-url request but need to trigger it
             * inside a timeframe of < 1sec from either setTimeout or setInterval.
             * If there is no response within this timespan, the user gets an error-notification with a "please retry" hint which
             * should then immediately resolve to the shortened url as this gets cached.
             */
            let syncAvailableUrl: string | undefined;
            let syncAvailableUrlError: unknown;
            const shortendedUrlSubscription =
                this.getShortenedConfigurationUrl$().subscribe({
                    next: (url) => {
                        syncAvailableUrl = url;
                    },
                    error: (error) => {
                        syncAvailableUrlError = error;
                    }
                });
            const iOSMaxUserInteractionTimeout = syncAvailableUrl ? 0 : 900;
            urlSource$ = timer(iOSMaxUserInteractionTimeout).pipe(
                take(1),
                map(() => {
                    if (syncAvailableUrlError) {
                        throw syncAvailableUrlError;
                    }
                    if (!syncAvailableUrl) {
                        this.notificationService.showError(
                            translate('SUMMARY.COPY_CONFIGURATION_LINK_ERROR')
                        );
                        throw new CustomError(
                            `Could not generate config-link in < ${iOSMaxUserInteractionTimeout}ms required for iOS to allow using it for copying it to clipboard`
                        );
                    }
                    return syncAvailableUrl;
                }),
                finalize(() => shortendedUrlSubscription.unsubscribe())
            );
        } else {
            // for all other devices, let's just wait for the shortened url to become available.
            urlSource$ = this.getShortenedConfigurationUrl$();
        }
        urlSource$
            .pipe(
                take(1),
                switchMap((url) => {
                    const copyResponse$ =
                        this.clipboardService.copyResponse$.pipe(
                            first(),
                            connectedPublishReplay()
                        );

                    this.clipboardService.copy(url);

                    return copyResponse$;
                }),
                this.takeUntilDestroy(),
                takeUntil(
                    // when the user hits the button multiple times...
                    this.userInteractionSubject.pipe(
                        filter((interaction) => interaction === 'CLIPBOARD')
                    )
                ),
                catchError(() => {
                    gtmGA4Track('copy_configuration_link_failed');
                    return EMPTY;
                })
            )
            .subscribe((clipboardResponse) => {
                if (clipboardResponse.isSuccess) {
                    this.onCopySuccess();

                    this.salesforceContextService
                        .getSalesforceContextData$({
                            includeDealerConfigurationUrl: true
                        })
                        .pipe(take(1))
                        .subscribe((contextData) => {
                            // SF Data Cloud: Configurator Get Link
                            sfEvent({
                                interaction: {
                                    ...SALESFORCE_EVENT_CONFIGURATOR_GET_LINK,
                                    modelCode: contextData.modelId ?? '',
                                    variantCode: contextData.productId ?? '',
                                    configUrl:
                                        contextData.dealerConfigurationLink ??
                                        ''
                                }
                            });
                        });
                } else {
                    this.onCopyError();
                }
            });
    }

    intentDownloadBeautyShots() {
        const notificationRef = this.notificationService.showNotification(
            translate(`SUMMARY.CONFIGURATION_SUMMARY.BEAUTY_SHOTS_WAITING`)
        );

        const getBeautyShots$ =
            this.staticRendererService.getRenderingsArchiveForActiveProduct$();

        combineLatest([
            this.handleServerCallInProgress(getBeautyShots$),
            this.labelHelperService.getActiveModelAndProductName$()
        ])
            .pipe(
                take(1),
                this.errorHandlerService.applyRetry({
                    messageProviderOnFinalError: () =>
                        translate(
                            'SUMMARY.CONFIGURATION_SUMMARY.ERROR.FAILED_BEAUTY_SHOTS'
                        )
                })
            )
            .subscribe({
                next: ([blob, activeModelName]) => {
                    downloadFile(
                        blob,
                        `Your ${activeModelName} Beauty Shots.zip`
                    );

                    gtmGA4Track('download_beauty_shots_bundle_success');
                },
                error: () => gtmGA4Track('download_beauty_shots_bundle_failed'),
                complete: () => {
                    notificationRef.dismiss();
                }
            });
    }

    intentShare(socialServiceId: string) {
        gtmGA4Track(`${socialServiceId}_share`);

        this.serverCallInProgress$
            .pipe(
                filter((callInProgress) => !callInProgress),
                take(1),
                switchMapTo(
                    defer(() =>
                        this.handleServerCallInProgress(
                            this.configurationSocialSharingService.shareCurrentConfiguration$(
                                socialServiceId
                            )
                        )
                    )
                )
            )
            .subscribe({
                error: (error) =>
                    this.errorHandlerService.showErrorMessage(
                        () =>
                            translate(
                                'SOCIAL_SHARING.ERROR.URL_SHARING_FAILED'
                            ),
                        error
                    )
            });
    }

    intentToggleTurntable() {
        gtmGA4Track('threesixty_view_click');
        this.dialogService
            .openFullscreen$(TurntableDialogComponent)
            .pipe(this.takeUntilDestroy())
            .subscribe();
    }

    intentSelectOption(id: string) {
        return this.IS_DEALER_BUILD ? null : this.optionSelected.next(id);
    }

    intentShowInfoForOption(optionCode: ExtendedUiOptionCode) {
        // check if we need to show a personalisation / accessories item or a regular option

        this.configurationCompositeItemService
            .mapToConfigurationCompositeItemModels$(
                this.productConfigurationSessionService.getOptionGroups$().pipe(
                    map((groups) => {
                        const pathToOption =
                            this.nodeLookupService.getNodePathToNode(
                                (node) => node.id === optionCode.id,
                                groups
                            );

                        if (!pathToOption) {
                            return undefined;
                        }

                        const rootNode = last(pathToOption);
                        if (!rootNode || !isOptionGroup(rootNode)) {
                            return undefined;
                        }

                        if (
                            [
                                environment.appConfig.configuration
                                    .identifierPersonalisation,
                                environment.appConfig.configuration
                                    .identifierAccessories
                            ].includes(rootNode.name.toUpperCase())
                        ) {
                            const subLevelGroup = pathToOption.reverse()[1];
                            return isOptionGroup(subLevelGroup)
                                ? subLevelGroup
                                : undefined;
                        }

                        return undefined;
                    })
                )
            )
            .pipe(
                take(1),
                map(
                    (
                        mappedCompositeItems
                    ):
                        | {
                              parent: ConfigurationCompositeItemModel;
                              child:
                                  | ConfigurationCompositeItemOptionModel
                                  | undefined;
                          }
                        | undefined => {
                        if (!mappedCompositeItems) {
                            return undefined;
                        }

                        // find the item in the tree of composite items
                        return mappedCompositeItems.reduce(
                            (foundItem, currentItem) => {
                                if (foundItem) {
                                    return foundItem;
                                }
                                if (currentItem.id === optionCode.id) {
                                    return {
                                        parent: currentItem,
                                        child: undefined
                                    };
                                }
                                const foundChild = currentItem.options?.find(
                                    (item) => item.id === optionCode.id
                                );

                                if (foundChild) {
                                    return {
                                        parent: currentItem,
                                        child: foundChild
                                    };
                                }

                                return undefined;
                            },
                            undefined
                        );
                    }
                ),
                withLatestFrom(this.getCompositeItemFallbackImageUrl$()),
                switchMap(
                    ([foundCompositeCombo, compositeItemFallbackImageUrl]) => {
                        if (!foundCompositeCombo) {
                            return this.infoLayerService.showInfoLayerForOptionCode$(
                                optionCode
                            );
                        }
                        const getPropertyWithFallbackToParent = (
                            prop: Extract<
                                keyof ConfigurationCompositeItemModel,
                                string
                            >
                        ) =>
                            foundCompositeCombo.child?.[prop] ??
                            foundCompositeCombo.parent[prop];
                        return this.infoLayerService.showInfoLayer$({
                            itemType: 'image',
                            label: getPropertyWithFallbackToParent('label'),
                            description:
                                getPropertyWithFallbackToParent(
                                    'description'
                                ) || '',
                            thumbnailUrls:
                                getPropertyWithFallbackToParent(
                                    'thumbnailUrls'
                                ),
                            thumbnailFallbackUrl: compositeItemFallbackImageUrl,
                            forceImageFit: 'cover',
                            isQOption:
                                getPropertyWithFallbackToParent('meta')
                                    ?.qOption,
                            hasAdditionalFittingCost:
                                getPropertyWithFallbackToParent('meta')
                                    ?.hasAdditionalFittingCost
                        });
                    }
                ),
                this.takeUntilDestroy()
            )
            .subscribe();
    }

    /**
     * Toggle between regular pricing and monthly rates of options.
     */
    intentToggleFinancialServicesRateDisplay() {
        this.financialServicesShowMonthlyRate =
            !this.financialServicesShowMonthlyRate;
    }

    @MemoizeObservable()
    getPricingSummaryModel$(): Observable<
        ExtendedConfigurationPricingSummaryModel | undefined
    > {
        return this.summaryService.getPricingSummaryModel$();
    }

    @MemoizeObservable()
    getPricingSummarySubEntryForId(
        subEntryId: string
    ): Observable<ExtendedConfigurationPricingSummarySubEntry | undefined> {
        return this.getPricingSummaryModel$().pipe(
            map((summaryModel) =>
                this.findSubEntryInSummarySubEntries(
                    subEntryId,
                    summaryModel?.subEntries ?? []
                )
            )
        );
    }

    @MemoizeObservable()
    getFinancialOfferRows$(): Observable<OfferRow[] | undefined> {
        return this.dealerHooksService
            .getFinancialServiceHooks()
            .getFinancialServicesOfferRows$();
    }

    @MemoizeObservable()
    getConfigurationOptionsSummaryGroups$(): Observable<
        ConfigurationSummaryGroup[] | undefined
    > {
        return this.summaryService.getSummaryGroups$();
    }

    @MemoizeObservable()
    getStandardFeaturesSummaryGroups$() {
        return this.summaryService.getStandardFeaturesSummaryGroups$();
    }

    onCopySuccess() {
        this.notificationService.showAutohideNotification(
            translate(`SUMMARY.COPY_CONFIGURATION_LINK_SUCCESS`)
        );
    }

    onCopyError() {
        this.notificationService.showError(
            translate(`SUMMARY.COPY_CONFIGURATION_LINK_ERROR`)
        );
    }

    onSelectedOptionsVsStandardFeaturesTabChanged(selectedIndex: number) {
        gtmGA4Track(
            selectedIndex === 0
                ? 'selected_options_click'
                : 'standard_features_click'
        );
    }

    @MemoizeObservable()
    getConfigurationUrl$(): Observable<string | undefined> {
        return this.configurationSessionInfoService.getCurrentConfigurationUrl$();
    }

    @MemoizeObservable()
    getNormalizedConfigurationUrl$(): Observable<string | undefined> {
        return this.configurationSessionInfoService.getNormalizedCurrentConfigurationUrl$();
    }

    @MemoizeObservable()
    isSliderVisible$() {
        return this.breakpointObserver
            .observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
            .pipe(map((state) => state.matches));
    }

    @MemoizeObservable()
    isBrochureAccessPossible$(): Observable<boolean> {
        if (this.IS_DEALER_BUILD) {
            return of(true);
        }

        // check public-access case
        return this.productDataService.getProductMeta$().pipe(
            map((productMeta) => {
                if (!productMeta) {
                    return false;
                }
                return !productMeta.disableBrochureAccessForPublic;
            })
        );
    }

    @MemoizeObservable()
    getAvailableCamerasWithComparisonImageSources$(): Observable<
        ImgComparisonElement[] | undefined
    > {
        return this.summaryImageProviderService.getSummaryComparisonImages$();
    }

    @MemoizeObservable()
    isFinancialServiceOfferAvailable$(): Observable<boolean> {
        return this.dealerHooksService
            .getFinancialServiceHooks()
            .getFinancialServicesOfferInfo$()
            .pipe(map((info) => !!info.offer));
    }

    getSelectedOption(
        collection: ExtendedUiOptionCollection
    ): ExtendedUiOptionCode | undefined {
        return collection.content
            .filter((item): item is ExtendedUiOptionCode => isOptionCode(item))
            .find((item) => item.selected);
    }

    @MemoizeObservable()
    getSummaryCollections$(): Observable<
        ExtendedUiOptionCollection[] | undefined
    > {
        return this.productConfigurationSessionService.getOptionGroups$().pipe(
            map((groups) => {
                if (!groups) {
                    return undefined;
                }
                return this.nodeLookupService.findNode(
                    (node): node is ExtendedUiOptionGroup =>
                        node.name ===
                            environment.appConfig.configuration
                                .identifierSummary &&
                        isExtendedUiOptionGroup(node),
                    groups
                )?.node;
            }),
            map((summaryNode) => {
                if (!summaryNode) {
                    return undefined;
                }

                const matcher = (node): node is ExtendedUiOptionCollection =>
                    isExtendedUiOptionCollection(node) &&
                    node.name !==
                        environment.appConfig.configuration
                            .identifierSummaryPricing;
                return this.nodeLookupService.collectNodes(matcher, [
                    summaryNode
                ]);
            })
        );
    }

    @MemoizeObservable()
    getTotalPriceLabel$(): Observable<string | undefined> {
        return combineLatest([
            this.getPricingSummaryModel$(),
            this.l10nService.getActiveCountry$(),
            this.i18nService.getActiveLang$()
        ]).pipe(
            map(([pricingSummaryModel, activeCountry]) => {
                if (!pricingSummaryModel) {
                    return undefined;
                }
                let translationKey = 'SUMMARY.PRICING.TOTAL_PRICE_RRP';
                if (pricingSummaryModel.pricingType === 'DNP') {
                    translationKey = 'SUMMARY.PRICING.TOTAL_PRICE_DNP';
                } else if (MSRP_COUNTRIES_SET.has(activeCountry || '')) {
                    translationKey = 'SUMMARY.PRICING.TOTAL_PRICE_MSRP';
                }

                return translate(translationKey);
            })
        );
    }

    @MemoizeObservable()
    getSlidesPerViewForSummarySlider$(): Observable<number> {
        const breakpointMapping: { [key: string]: number } = {
            [AmlBreakpoints.XSmall]: 1,
            [AmlBreakpoints.Small]: 2,
            [AmlBreakpoints.Medium]: 2,
            [AmlBreakpoints.Large]: 3,
            [AmlBreakpoints.XLarge]: 4
        };
        const breakpoints = keys(breakpointMapping).reverse();
        return this.breakpointObserver.observe(breakpoints).pipe(
            map((state) => {
                const breakpointStates = state.breakpoints;
                const matchingBreakpoint = breakpoints.find(
                    (breakpoint) => breakpointStates[breakpoint]
                );
                return matchingBreakpoint
                    ? breakpointMapping[matchingBreakpoint]
                    : 1;
            })
        );
    }

    @MemoizeObservable()
    private getCompositeItemFallbackImageUrl$() {
        return this.configurationCompositeItemService.getCompositeItemFallbackImageUrl$();
    }

    private getShortenedConfigurationUrl$() {
        // we need to wait for a possible pending request to prevent generating multiple codes for the same configuration
        return this.serverCallInProgress$.pipe(
            filter((callInProgress) => !callInProgress),
            take(1),
            switchMap(() =>
                defer(() =>
                    this.handleServerCallInProgress(
                        this.configurationSocialSharingService.getShortenedConfigurationUrl$()
                    )
                )
            )
        );
    }

    private getShortCode$(): Observable<string> {
        // we need to wait for a possible pending request to prevent generating multiple codes for the same configuration
        return this.serverCallInProgress$.pipe(
            filter((callInProgress) => !callInProgress),
            take(1),
            switchMapTo(
                defer(() =>
                    this.handleServerCallInProgress(
                        this.getConfigurationUrl$().pipe(
                            take(1),
                            switchMap((configUrl) => {
                                if (!configUrl) {
                                    throw new IllegalStateError(
                                        'Missing configuration URL'
                                    );
                                }
                                return this.urlShortenerService.generateShortCode$(
                                    configUrl
                                );
                            }),
                            this.errorHandlerService.applyRetry({
                                messageProviderOnFinalError: () =>
                                    translate(
                                        'AML_CODE.CREATE.ERROR.FAILED_GENERATING_CODE'
                                    )
                            })
                        )
                    )
                )
            )
        );
    }

    private findSubEntryInSummarySubEntries(
        id: string,
        subEntries: ExtendedConfigurationPricingSummarySubEntry[]
    ): ExtendedConfigurationPricingSummarySubEntry | undefined {
        const foundEntry = subEntries.find((entry) => entry.id === id);
        if (foundEntry) {
            return foundEntry;
        }

        let foundChildEntry;
        for (const subEntry of subEntries) {
            if (subEntry.children) {
                foundChildEntry = this.findSubEntryInSummarySubEntries(
                    id,
                    subEntry.children
                );
                if (foundChildEntry) {
                    break;
                }
            }
        }
        return foundChildEntry;
    }

    private initStickyHeaderBehavior() {
        const summarySliderElement$ = this.summarySliderQueryList.changes.pipe(
            startWith(undefined),
            map(() => this.summarySliderQueryList.first?.nativeElement)
        );

        // init sticky-offset calculation
        summarySliderElement$
            .pipe(
                switchMap((summarySliderElement) => {
                    if (!summarySliderElement) {
                        return of(undefined);
                    }

                    return new Observable((subscriber) => {
                        const resizeObserver = new ResizeObserver(() => {
                            subscriber.next(summarySliderElement);
                        });
                        resizeObserver.observe(summarySliderElement);

                        return () => {
                            resizeObserver.disconnect();
                        };
                    });
                }),
                filter((element): element is HTMLDivElement => !!element),
                this.takeUntilDestroy()
            )
            .subscribe((summarySliderElement) => {
                if (
                    window.getComputedStyle(summarySliderElement).position !==
                    'sticky'
                ) {
                    this.renderer.removeStyle(summarySliderElement, 'top');
                    return;
                }
                const stickyTop = -1 * summarySliderElement.clientHeight + 60;

                this.renderer.setStyle(
                    summarySliderElement,
                    'top',
                    `${stickyTop}px`
                );
            });
    }

    private initPaginationVisibleStatus() {
        // init pagination visible status
        this.summarySliderPaginationVisibleStatus$ =
            this.summarySliderQueryList.changes.pipe(
                startWith(undefined),
                map(() => this.summarySliderQueryList.first?.nativeElement),
                switchMap((summarySliderElement) => {
                    if (!summarySliderElement) {
                        return EMPTY;
                    }

                    return new Observable<boolean>((subscriber) => {
                        const intersectionObserver = new IntersectionObserver(
                            (entries) => {
                                entries.forEach((entry) =>
                                    subscriber.next(entry.isIntersecting)
                                );
                            },
                            {
                                threshold: 0.5
                            }
                        );
                        intersectionObserver.observe(summarySliderElement);

                        return () => {
                            intersectionObserver.disconnect();
                        };
                    });
                }),
                this.takeUntilDestroy()
            );
    }

    private initAutoplayStatus() {
        this.summarySliderAutoplayStatus$ =
            this.summarySliderPaginationVisibleStatus$;
    }
}
